<template>
  <div class="vx-row">
    <div class="vx-col md:w-4/5">
      <vx-card title="إضافة منتج">
        <div class="mt-5">
          <form-wizard
            color="rgba(var(--vs-primary), 1)"
            :title="null"
            :subtitle="null"
            nextButtonText="التالي"
            backButtonText="السابق"
            finishButtonText="إضافة"
            @on-complete="initializeData"
          >
            <tab-content
              title="بيانات المنتج"
              class="mb-5"
              icon="feather icon-home"
            >
              <!-- tab 1 content -->
              <div class="vx-row">
                <div class="vx-col md:full w-full mt-5" id="title">
                  <vs-input
                    label="إسم المنتج"
                    name="الاسم"
                    v-model="title"
                    class="w-full"
                    v-validate="'required'"
                  />
                  <span class="text-danger">{{ errors.first("الاسم") }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5" id="title">
                  <vs-input
                    label="Sku"
                    name="Sku"
                    v-model="sku"
                    class="w-full"
                    v-validate="'required'"
                  />
                  <span class="text-danger">{{ errors.first("sku") }}</span>
                </div>
                <div
                  class="vx-col md:w-1/2 w-full mt-5"
                  v-if="classAAccess"
                  id="brand"
                >
                  <vs-select
                    v-model="brand"
                    name="العلامة التجارية"
                    class="w-full select-large"
                    label="العلامة التجارية"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item"
                      :text="item.name"
                      v-for="(item, index) in brands"
                      class="w-full"
                    />
                  </vs-select>
                  <span class="text-danger">{{
                    errors.first("العلامة التجارية")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" v-if="classAAccess">
                  <vs-input
                    :label="'السعر الاصلي ب' + brand.original_currency.base"
                    name="السعر الأصلي"
                    @blur="calculateSellingPrice"
                    v-model="originalPrice"
                    class="w-full"
                    v-validate="'required|min:0|decimal'"
                  />
                  <span class="text-danger">{{
                    errors.first("السعر الأصلي")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <label>نوع النسبة</label>
                  <select
                    class="form-control"
                    @blur="calculateSellingPrice"
                    @input="calculateSellingPrice"
                    v-model="percentageType"
                  >
                    <option value="brand">العلامة التجارية</option>
                    <option value="static">نسبة سياسة التسعير</option>
                  </select>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input
                    :label="'السعر ب' + brand.selling_currency.base"
                    name="السعر"
                    v-model="price"
                    class="w-full"
                    v-validate="'required|min_value:' + min_price"
                  />
                  <span class="text-danger">{{ errors.first("السعر") }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input
                    label="السعر بعد التخفيض"
                    name="سعر التخفيض"
                    v-model="discountPrice"
                    class="w-full"
                    v-validate="'max_value:' + price"
                  />
                  <span class="text-danger">{{
                    errors.first("سعر التخفيض")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input
                    suffix="%"
                    label="نسبة الزيادة للمنتج"
                    name="نسبة الزيادة"
                    v-model="productPercentageIncrease"
                    @blur="calculatePriceAfterPercentage"
                    class="w-full"
                    v-validate="'integer|max_value:100|min_value:0'"
                  />

                  <span class="text-danger">{{
                    errors.first("نسبة الزيادة")
                  }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" v-if="classAAccess">
                  <vs-input
                    label="رقم الـ OEM"
                    name="OEM"
                    v-model="OEM"
                    class="w-full"
                    v-validate="'required'"
                  />
                  <span class="text-danger">{{ errors.first("OEM") }}</span>
                </div>

                <div class="vx-col md:w-1/2 w-full mt-5" v-if="classAAccess">
                  <vs-input
                    label="رابط المنتج"
                    name="رابط المنتج"
                    v-model="url"
                    class="w-full"
                    v-validate="'url'"
                  />
                  <span class="text-danger">{{
                    errors.first("رابط المنتج")
                  }}</span>
                </div>
              </div>
            </tab-content>

            <!-- tab 2 content -->
            <tab-content
              title="تصنيف المنتبج"
              class="mb-5"
              icon="feather icon-briefcase"
            >
              <div class="vx-row">
                <div class="vx-col md:w-full w-full mt-5">
                  <vs-select
                    v-model="gender"
                    name="الجنس"
                    class="w-full select-large"
                    label="الجنس"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item"
                      :text="item.name"
                      v-for="(item, index) in options"
                      class="w-full"
                    />
                  </vs-select>
                  <span class="text-danger">{{ errors.first("الجنس") }}</span>
                </div>

                <!-- Season Section -->
                <div class="vx-col md:w-full w-full mt-5">
                  <vs-select
                    v-model="season"
                    name="season"
                    class="w-full select-large"
                    label="اختر الموسم"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item"
                      :text="item"
                      v-for="(item, index) in seasons"
                      class="w-full"
                    />
                  </vs-select>
                  <span class="text-danger">{{ errors.first("الجنس") }}</span>
                </div>
                <!-- End Season Section -->

                <!-- Note Section -->
                <div class="vx-col md:w-full w-full mt-5">
                  <div class="vx-col md:w-full w-full">
                    <vs-textarea
                      v-model="note"
                      label="ملاحظـة بتفاصيل المنتج"
                      class="mb-0 mt-10 mt-6"
                      rows="3"
                    />
                  </div>
                </div>
                <!-- End Note Section -->

                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-select
                    v-model="category"
                    name="التصنيف الرئيسي"
                    class="w-full select-large"
                    label="التصنيف الرئيسي"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item"
                      :text="item.name"
                      v-for="(item, index) in gender.categories"
                      class="w-full"
                    />
                  </vs-select>
                  <span class="text-danger">{{
                    errors.first("التصنيف الرئيسي")
                  }}</span>
                </div>
                <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-select
                    v-model="subCategory"
                    name="التصنيف الفرعي"
                    class="w-full select-large"
                    label="التصنيف الفرعي"
                    v-validate="'required'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item"
                      :text="item.name"
                      v-for="(item, index) in category.sub_categories"
                      class="w-full"
                    />
                    <span class="text-danger">{{
                      errors.first("التصنيف الفرعي")
                    }}</span>
                  </vs-select>
                </div>

                <div class="vx-col md:w-full w-full">
                  <vs-textarea
                    v-model="description"
                    label="وصف مفصّل عن المنتج"
                    class="mb-0 mt-10 mt-6"
                    rows="3"
                    v-validate="'required'"
                  />
                  <span class="text-danger">{{ errors.first("الوصف") }}</span>
                </div>
              </div>
            </tab-content>
            <tab-content
              title="كميات المنتج"
              class="mb-5"
              icon="feather icon-image"
            >
              <div
                class="vx-row vx-col w-full mt-5"
                v-for="(item, index) in quantities"
                :key="index"
              >
                <div class="vx-col md:w-1/5">
                  <label for="color">اللون</label><br />
                  <v-swatches
                    v-model="item.color"
                    v-validate="'required'"
                    @input="getColor"
                    :swatches="colorValues"
                    row-length="6"
                    show-border
                    id="color"
                    popover-x="left"
                    name="اللون"
                  >
                    <vs-input
                      slot="trigger"
                      :value="item.color"
                      class="form__input__element w-full"
                      readonly
                  /></v-swatches>
                  <span class="text-danger">{{ errors.first("اللون") }}</span>
                </div>
                <!-- size -->
                <div
                  class="vx-row vx-col md:w-2/5"
                  style="display: flex; justify-content: space-between"
                >
                  <vs-select
                    v-model="item.type"
                    class="w-full select-large md:w-1/3"
                    label="النوع"
                    @change="clear(item)"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.key"
                      v-for="(item, index) in types"
                      class="w-full"
                    />
                  </vs-select>

                  <!--
                  <vs-select
                    v-model="item.size"
                    class="w-full select-large md:w-2/3"
                    v-validate="'required'"
                    name="المقاس"
                    label="المقاسات المتوفرة من اللون"
                    multiple
                    @input="syncQuantity(item)"
                  >
               

                    <vs-select-item
                      :key="index"
                      :value="s"
                      :text="`${s}`"
                      v-for="(s, index) in sizes[item.type]"
                      class="w-full"
                    />
                  </vs-select>-->

                  <div
                    style="
                      display: flex;
                      align-items: baseline;
                      flex-direction: column;
                    "
                  >
                    <label class="mb-1">المقاسات</label>
                    <vue-multi-select
                      style="w-full text-align:center; height:fit-content;"
                      :options="option"
                      :btnLabel="() => item.size.slice(0, 3).join(' , ')"
                      v-model="item.size"
                      @input="syncQuantity(item)"
                      :selectOptions="sizes[item.type]"
                    />
                  </div>

                  <span class="text-danger">{{ errors.first("المقاس") }}</span>
                </div>
                <div class="vx-col md:w-1/5">
                  <div v-for="(q, index) in item.size" :key="index">
                    <vs-input
                      :label="'الكمية من المقاس ' + q"
                      v-model="item.quantity[q]"
                      class="w-full"
                      :name="'الكمية من المقاس ' + q"
                      v-validate="'required'"
                    />
                    <span class="text-danger">{{
                      errors.first("الكمية من المقاس " + q)
                    }}</span>
                  </div>
                </div>
                <div class="vx-col md:w-1/5 mt-5 ml-2">
                  <div class="vx-row">
                    <vs-button
                      @click="repeatForm"
                      class="vx-col md:w-3/5 mb-base p-1"
                      type="border"
                      icon-pack="feather"
                      icon="icon icon-plus"
                      >لون اخر</vs-button
                    >
                    <vs-button
                      @click="deleteRow(index)"
                      class="vx-col md:w-1/5 mb-base ml-1"
                      type="border"
                      icon-pack="feather"
                      icon="icon icon-x"
                    ></vs-button>
                  </div>
                </div>
              </div>
            </tab-content>
            <!-- tab 3 content -->
            <tab-content
              title="صور المنتج"
              class="mb-5"
              icon="feather icon-image"
            >
              <div class="vx-row align-items-center">
                <div v-for="(item, index) in quantities" :key="index">
                  <div v-if="item.color">
                    <label for="color"
                      >صور اللون
                      <v-swatches
                        v-model="item.color"
                        disabled
                        popover-x="left"
                      ></v-swatches
                    ></label>
                    <VueFileAgent
                      class="mr-5 mt-2"
                      dir="ltr"
                      :uploadUrl="'https://www.mocky.io/v2/5d4fb20b3000005c111099e3'"
                      ref="vueFileAgent"
                      :theme="'list'"
                      :multiple="true"
                      :deletable="true"
                      @beforedelete="fileDeleted($event, item.color)"
                      :meta="true"
                      :accept="'image/*,.zip'"
                      :maxSize="'5MB'"
                      :maxFiles="8"
                      :helpText="'اختر الصور'"
                      :errorText="{
                        type: 'الرجاء اختيار صور فقط',
                        size: 'الرجاء اختبار صور حجمها لا يزيد عن 5 ميقا بايت',
                      }"
                      v-model="medias[item.color]"
                    >
                    </VueFileAgent>
                  </div>
                </div>
              </div>
            </tab-content>
          </form-wizard>
        </div>
      </vx-card>
    </div>
    <div class="vx-col md:w-1/5">
      <vx-card
        title="تمييز المنتج"
        class="mb-5 text-center"
        v-if="featuredAccess"
      >
        <vs-switch v-model="isFeatured" />
      </vx-card>
      <vx-card title="هل المنتج مشحون" class="text-center" v-if="classAAccess">
        <vs-switch v-model="isShipped" />
      </vx-card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapGetters } from "vuex";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import axios from "axios";

export default {
  components: {
    FormWizard,
    TabContent,
    VSwatches,
    vueMultiSelect,
  },
  data() {
    return {
      option: {
        multi: true,
        labelList: "elements",
        btnlabel: () => "مقاسات",
      },
      values: [],

      productPercentageIncrease: 0,
      classAAccess: this.GetClassAAccess(),
      featuredAccess: this.GetFeaturedAccess(),
      min_price: 0,
      title: "",
      sku: "",
      OEM: "",
      url: "",
      brand: {
        original_currency: { base: "", rate: 1 },
        selling_currency: { base: "", rate: 1 },
      },
      price: "",
      percentageType: "brand",
      originalPrice: "",
      discountPrice: 0,
      gender: "",
      category: "",
      subCategory: "",
      description: "",
      note: "",
      season: "",
      isFeatured: false,
      isShipped: false,
      medias: {},
      quantities: [
        {
          type: "letters",
          size: "",
          color: "",
          quantity: {},
        },
      ],
      types: this.getSizeTypes(),
      sizes: this.getSizes(),
    };
  },
  computed: {
    seasons() {
      return ["صيفي", "شتوي"];
    },

    options() {
      return this.$store.state.products.options;
    },
    brands() {
      return this.$store.state.products.brands;
    },
    colors() {
      return this.$store.state.products.colors;
    },
    colorValues() {
      return this.$store.state.products.colors.map(
        (item) => `#${item.color_value}`
      );
    },
    offers() {
      return this.$store.state.products.offers;
    },
  },
  methods: {
    ...mapActions("products", [
      "createProduct",
      "fetchBrands",
      "fetchColors",
      "fetchOffers",
      "fetchProductOptions",
      "getSubCategories",
      "getCategories",
    ]),
    ...mapGetters("products", ["getCurrentPage"]),
    deleteRow(index) {
      this.quantities.splice(index, 1);
    },
    async calculateSellingPrice() {
      if (this.brand) {
        let rate1 = 1,
          rate2 = 1;
        if (this.brand.original_currency_id != this.brand.selling_currency_id) {
          rate1 = this.brand.original_currency.rate;
          rate2 = this.brand.selling_currency.reverse_rate;
        }
        this.min_price = this.originalPrice * rate1 * rate2;
        if (this.percentageType == "static") {
          try {
            let response = await axios.post(
              "https://boutiquette-store.ly/get-static-price",
              {
                price: this.min_price,
              }
            );
            if (response.data.data.length > 0) {
              this.price =
                this.min_price +
                (response.data.data[0].selling_price * this.min_price) / 100;
            }
            // this.price = this.min_price + (((response.data[0].selling_price)*this.min_price)/100);
          } catch (error) {
            null;
          }
        } else {
          this.price = Math.ceil(
            this.min_price * this.brand.increase_percentage
          );
        }
      }
    },

    calculatePriceAfterPercentage() {
      // it gets the selling price and Adds the productPercentageIncrease prop to it
      this.price = Math.ceil(
        this.min_price *
          (this.productPercentageIncrease / 100 +
            this.brand.increase_percentage)
      );
    },
    fileDeleted(e, color) {
      let index = this.medias[color].indexOf(e);
      this.medias[color].splice(index, 1);
    },
    clear(item) {
      item.quantity = {};
      item.size = [];
    },
    repeatForm() {
      this.quantities.push({
        type: "letters",
        size: "",
        color: "",
        quantity: {},
      });
    },
    getColor(i) {
      this.medias[i] = [];
    },
    select(arr, obj) {
      for (var k in obj) {
        return arr.reduce((o, c) => {
          if (obj.hasOwnProperty(c)) {
            o[c] = obj[c];
          }
          return o;
        }, {});
      }
    },
    syncQuantity(item) {
      if (Object.keys(item.quantity).length) {
        let result = this.select(item.size, item.quantity);
        item.quantity = result;
      }
    },
    initializeData() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "هناك خطأ في إدخال البيانات الرجاء التحقق",
            color: "danger",
            acceptText: "إغلاق",
          });
          return;
        } else if (Object.values(this.medias).flat().length == 0) {
          this.$vs.dialog({
            title: "خطأ في إدخال البيانات",
            text: "يجب إدخال على الاقل صورة واحدة",
            color: "danger",
            acceptText: "إغلاق",
          });
          return;
        } else {
          let i = 0;
          var bodyFormData = new FormData();
          var quantities = [];
          var medias = [];
          //init quantities by detaching the quantities compact objects of one color and an array of sizes and
          //to one color and one size to accommodate the backend
          this.quantities.forEach((item) => {
            item.size.forEach((size) => {
              var q = {};
              q.color = item.color;
              q.size = size;
              q.quantity = 30;
              quantities.push(q);
            });
          });
          // init pictures by detaching the media object of arrays with colors as keys to and array of objects
          // with each media alone with its color as a normal value
          Object.keys(this.medias).forEach((key) => {
            this.medias[key].forEach((item) => {
              var media = {};
              media.image = item.file;
              media.color_id = key;
              medias.push(media);
            });
          });

          for (i = 0; i < quantities.length; i++) {
            bodyFormData.append(
              "quantities[" + i + "][size]",
              quantities[i].size
            );
            bodyFormData.append(
              "quantities[" + i + "][quantity]",
              Number(quantities[i].quantity)
            );
            bodyFormData.append(
              "quantities[" + i + "][color]",
              quantities[i].color.replace("#", "")
            );
          }

          for (i = 0; i < medias.length; i++) {
            bodyFormData.append("medias[" + i + "][file]", medias[i].image);
            bodyFormData.append(
              "medias[" + i + "][color]",
              medias[i].color_id.replace("#", "")
            );
          }

          if (this.classAAccess) {
            bodyFormData.set("brand_id", this.brand.id);
          }
          bodyFormData.set("title", this.title);
          bodyFormData.set("sku", this.sku);
          bodyFormData.set("OEM", this.OEM);
          bodyFormData.set("description", this.description);
          bodyFormData.set("note", this.note);
          bodyFormData.set("season", this.season);
          bodyFormData.set("price", this.price);
          bodyFormData.set("original_price", this.originalPrice);
          bodyFormData.set(
            "discount_price",
            this.discountPrice ? this.discountPrice : this.price
          );
          bodyFormData.set("gender_id", this.gender.id);
          bodyFormData.set("url", this.url);
          bodyFormData.set("category_id", this.category.id);
          bodyFormData.set("sub_category_id", this.subCategory.id);
          bodyFormData.set("is_shipped", this.isShipped ? 1 : 0);
          bodyFormData.set("is_featured", this.isFeatured ? 1 : 0);
          this.submit(bodyFormData);
        }
      });
    },
    submit(data) {
      console.log("jj");
      this.$vs.loading();
      this.createProduct(data)
        .then(() => {
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تمت إضافة المنتج بنجاح",
            color: "success",
          });
          this.$router.push({
            name: "product-list",
            query: { page: this.getCurrentPage() },
          });
        })
        .catch((error) => {
          this.$vs.loading.close();
          let errorMessage = error.response.data.errors
            ? error.response.data.errors
            : error.message;

          this.errorDialog(errorMessage);
        });
    },
  },

  created() {
    this.fetchBrands();
    this.fetchColors();
    this.fetchOffers();
    this.fetchProductOptions();
    this.getCategories();
    this.getSubCategories();
  },
};
</script>
<style scoped>
.br-1 {
  border: 1px solid aliceblue;
}
.vue-swatches__swatch.vue-swatches__swatch--border,
.vue-swatches__swatch.vue-swatches__swatch--selected {
  box-shadow: none !important;
}
</style>
